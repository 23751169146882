// src/components/LogoText.js
import React from 'react';
import '../styles/LogoText.css'; // Assure-toi d'importer le CSS pour l'animation

const LogoText = () => {
  return (
    <div className="logo-text-container">
      <h1 className="logo-text">Rimesco Technology</h1>
    </div>
  );
};

export default LogoText;
