import React from "react";
import '../styles/Services.css';  // Import des styles pour cette page
import ServicesContent from '../content/ServicesContent';
import ConsultingContent from '../content/ConsultingContent';
import WebMobileContent from '../content/WebMobileContent';

function Services() {
  return (
    <div className="services">
      <div className="services-header">
        <h1>{ServicesContent.title}</h1>
        <p>{ServicesContent.description}</p>
      </div>
      
      <div className="section">
        <h2>{ConsultingContent.title}</h2>
        {ConsultingContent.paragraphs.map((paragraph, index) => (
          <p key={index}>{paragraph}</p>
        ))}
      </div>

      {/* Bloc de contenu pour Web et Mobile */}
      <div className="section">
        <h2>{WebMobileContent.title}</h2>
        {WebMobileContent.paragraphs.map((paragraph, index) => (
          <p key={index}>{paragraph}</p>
        ))}
      </div>
    </div>
  );
}

export default Services;
