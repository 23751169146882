import React from "react";
import '../styles/About.css';  // Import des styles pour cette page
import AboutContent from '../content/AboutContent';

function About() {
  return (
    <div className="about">
      <h1>{AboutContent.title}</h1>
      <p>{AboutContent.introduction}</p>
      
      <div className="values-container">
        {AboutContent.values.map((value, index) => (
          <div
            key={index}
            className={`value-pin ${index % 2 === 0 ? 'left' : 'right'}`}
          >
            <h2>{value.title}</h2>
            <p>{value.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default About;
