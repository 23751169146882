import React from "react";
import '../styles/Home.css';  // Import des styles pour cette page
import '../styles/Footer.css'
import LogoText from './LogoText';

import ConsultingContent from '../content/ConsultingContent';
import WebMobileContent from '../content/WebMobileContent';

function Home() {
    const handleReserveClick = () => {
      window.open('https://calendly.com/rimescot', '_blank'); // Remplace 'your-username' par ton nom d'utilisateur Calendly
    };

  return (
    <div className="home">
      
      {/* Section fond d'écran avec le logo et les boutons de navigation */}
      <div className="home-background">
        <LogoText />
        <div className="top-right-buttons">
          <a href="/about" className="top-right-button">About Us</a>
          <a href="/contact" className="top-right-button">Contact</a>
          <a href="/careers" className="top-right-button">Careers</a>
          <a href="/blog" className="top-right-button">Blog</a>
        </div>
      </div>

      {/* Section principale avec le contenu de la page d'accueil */}
      <div className="home-top-background">
  <div className="home-content">
    <p className="home-slogan">RIMESCO TECHNOLOGY</p>
    <p className="home-description">Votre partenaire développement logiciel et conseil</p>
    <p className="home-description">
      Deux axes fondamentaux de notre expertise.
    </p>
    <a href="/services" className="home-link">Découvrez nos services</a>
  </div>
</div>


      {/* Bloc de contenu supplémentaire pour Conseil et Services */}
      <div className="section">
        <h2>{ConsultingContent.title}</h2>
        {ConsultingContent.paragraphs.map((paragraph, index) => (
          <p key={index}>{paragraph}</p>
        ))}
      </div>

      {/* Bloc de contenu pour Web et Mobile */}
      <div className="section">
        <h2>{WebMobileContent.title}</h2>
        {WebMobileContent.paragraphs.map((paragraph, index) => (
          <p key={index}>{paragraph}</p>
        ))}
      </div>
      {/* Bouton de réservation fixe */}
      <button className="reserve-button" onClick={handleReserveClick}>
        Je réserve un échange
      </button>
      {/* Bloc de bas de page */}
      <footer className="footer">
        <div className="footer-content">
          <div className="footer-left">
            <p>&copy; {new Date().getFullYear()} rimesco Technology</p>
          </div>
          <div className="footer-right">
            <p>
              Adresse: <a href="mailto:contact@rimesco.fr">contact@rimesco.fr</a>
            </p>
            <p>
              <a href="/legal-notice">Mentions légales</a>
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
}
export default Home;
