const ServicesContent = {
    title: "Nos Services",
    description: "Retrouvez nous en France",
    services: [
      "Développement de logiciels",
      "Conseil en infrastructure",
      "Cybersécurité",
      "Support technique"
    ]
  };
  
  export default ServicesContent;
  