const AboutContent = {
    title: "À propos de nous",
    introduction: "Nous sommes une jeune entreprise dynamique, dédiée à offrir des solutions innovantes et efficaces pour accompagner votre transformation numérique.",
    values: [
      {
        title: "Expertise",
        description: "Nous possédons une expertise pointue dans le domaine du développement logiciel et du conseil, assurant des solutions sur mesure qui répondent à vos besoins spécifiques."
      },
      {
        title: "Transparence",
        description: "Nous privilégions la proximité avec nos clients, offrant un accès facile et une visibilité totale sur nos méthodes de travail pour garantir une collaboration fluide et ouverte."
      },
      {
        title: "Méthode",
        description: "Nous adoptons les meilleures approches en matière de produit et de technologie, assurant que nos solutions sont à la fois innovantes et adaptées aux défis de votre secteur."
      }
    ]
  };
  
  export default AboutContent;
  