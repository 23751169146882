import React from "react";
import '../styles/Projects.css';  // Import des styles pour cette page

function Projects() {
  return (
    <div className="projects">
      <h1>Projets en cours</h1>
      <p>Voici un aperçu de nos projets en cours...</p>
    </div>
  );
}

export default Projects;
