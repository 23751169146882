import React from "react";
import '../styles/Blog.css';  // Import des styles pour cette page

function Blog() {
  return (
    <div className="blog">
      <h1>Blog</h1>
      <p>Découvrez nos projets types pour la formation...</p>
    </div>
  );
}

export default Blog;
